export default {
  common: {
    howToUse: '如何使用',
    star: 'GitHub 赞一个',
  },
  home: {
    description: '为你的博客创建封面图片现在变得非常容易',
    openEditor: '打开编辑器',
    whyCoverImages: '为什么封面图片比你想象的更重要？',
    reason1: '每天约有 <b>700 万篇</b> 博文被发表。随着新时代博客工具的兴起，这个数字还会持续上升。',
    reason2: '一个好的封面图片比随机的库存图片能带来 <b>更高的转化率</b>。',
    step: {
      title: '使用 CoverView 可以在 <span class="text-indigo-400">几秒钟</span> 内创建封面图片',
      1: '添加博客文章的标题和作者',
      2: '自定义颜色、字体、图标等',
      3: [
        '从不同的主题中选择',
        'Unsplash 集成、个人品牌定制图标等',
      ],
    },
    supportsPlatforms: '支持 Hashnode、Dev.to 和 Hugo FixIt 等平台',
    shareOnTwitter: '分享到 Twitter',
    summary: {
      title: '简单、快速、易用',
      content: '这样你就可以专注于撰写博客，而不必担心封面图片了。',
    },
    tryItNow: '免费！立即试用',
    footer: {
      copyright: '为爱发电 💛 <a href="https://github.com/Lruihao" class="font-semibold" target="_blank" rel="noreferrer">Lruihao</a>',
      authorBlog: '查看我的 <a href="https://lruihao.cn/projects/" target="_blank" rel="noreferrer" class="text-purple-400 hover:text-pink-400">其他作品</a>',
      thanks: '感谢原作者 ',
    },
  },
  faq: {
    title: '常见问题',
    questions: [
      {
        q: '什么是 CoverView？',
        a: 'CoverView 是一个快速、轻松地为博客创建封面图片的工具。',
      },
      {
        q: 'CoverView 是免费的吗？',
        a: '是的！CoverView 绝对是免费使用的。',
      },
      {
        q: '我可以上传自定义品牌图标吗？',
        a: '可以！只需在图标部分搜索并选择 <span class="font-semibold">自定义</span>，即可上传自己的图标以个性化你的封面图片。<br /><span class="italic mt-2">查看 <a class="text-blue-500" href="https://twitter.com/WankhadeRutik/status/1518270774335111168?s=20&t=XMjbJpGAC7anadJ690_DUg" rel="noreferrer" target="_blank">示例</a></span>。',
      },
      {
        q: '我可以将 CoverView 用于非技术/个人博客吗？',
        a: '可以！为什么不呢？尽管 CoverView 是针对技术博客而构建的，但你仍然可以将其用于个人博客，查看时尚主题以获取更多信息。',
      },
      {
        q: '为什么使用 CoverView？',
        a: '因为它简单、快速、易用。几秒钟就能创建封面图像，为什么还要花几个小时来设计呢？',
      },
      {
        q: '想要支持/赞助这个项目吗？',
        // a: 'If CoverView adds value in your life and you wish to support this project, you can sponsor me with <a class="font-semibold text-green-400 hover:underline" href="https://lruihao.cn/images/wechatpay.jpg" rel="noreferrer" target="_blank">WeChat</a>/<a class="font-semibold text-blue-400 hover:underline" href="https://lruihao.cn/images/alipay.jpg" rel="noreferrer" target="_blank">Alipay</a> or <a class="font-semibold text-pink-400 hover:underline" href="https://www.buymeacoffee.com/rutikwankhade" rel="noreferrer" target="_blank">buy Rutik Wankhade a coffee</a>',
        a: '如果 Coverview 为你的生活增添了价值，并且你希望支持这个项目，你可以通过 <a class="font-semibold text-green-400 hover:underline" href="https://lruihao.cn/images/wechatpay.jpg" rel="noreferrer" target="_blank">微信</a> / <a class="font-semibold text-blue-400 hover:underline" href="https://lruihao.cn/images/alipay.jpg" rel="noreferrer" target="_blank">支付宝</a> 赞助我或者给 Rutik Wankhade <a class="font-semibold text-pink-400 hover:underline" href="https://www.buymeacoffee.com/rutikwankhade" rel="noreferrer" target="_blank">买杯咖啡</a>。',
      },
    ],
    tips: {
      title: '想知道秘密吗？点击我',
      content: '博客标题至少包含 8 个字，点击率提高 21%',
    },
  },
  editor: {
    title: '博客标题',
    author: '作者',
    icon: '图标',
    custom: '自定义',
    font: '字体',
    color: '颜色',
    pattern: '底纹图案',
    platform: '平台/尺寸',
    resetBtn: '重置所有',
    selectImgTips: '点击选择图片',
    downloadBtn: '下载',
    copyBtn: '复制',
    themes: '主题',
    imageListEmpty: '暂无图片',
    uploadScreenshot: '点击上传截图或按 Ctrl+V 粘贴',
    searchPlaceholder: '搜索图片',
    fonts: {
      'font-Virgil': 'Virgil',
      'font-MMT': '沐目体',
      'font-Anek': 'Anek Latin',
      'font-Inter': 'Inter',
      'font-Poppins': 'Poppins',
      'font-mono': '等宽字体',
      'font-sans': '无衬线字体',
      'font-serif': '衬线字体',
    },
  },
  pagination: {
    summary: '共 <span class="font-medium">{{total}}</span> 个结果，<span class="font-medium">{{page}}</span>/<span class="font-medium">{{totalPages}}</span> 页',
    prev: '上一页',
    next: '下一页',
  },
  orientation: {
    all: '全部',
    landscape: '横向',
    portrait: '纵向',
    squarish: '方形',
  },
  resultColors: {
    all: '全部',
    black_and_white: '黑白',
    black: '黑色',
    white: '白色',
    yellow: '黄色',
    orange: '橙色',
    red: '红色',
    purple: '紫色',
    magenta: '品红',
    green: '绿色',
    teal: '青色',
    blue: '蓝色',
  },
}
