export default {
  common: {
    howToUse: 'How to use',
    star: 'Star on GitHub',
  },
  home: {
    description: 'Creating cover images for your blogs is now super easy',
    openEditor: 'Open Editor',
    whyCoverImages: 'Why cover images are more important than you think?',
    reason1: 'Around <b>7 million</b> blog posts are published daily. And with the rise of new-age blogging tools, it will only go up.',
    reason2: 'A good cover image can lead to <b>higher conversion rate</b> than a random stock image.',
    step: {
      title: 'With CoverView, you can create cover images in <span class="text-indigo-400">seconds</span>.',
      1: 'Add title and author of your blog post',
      2: 'Customize with colors, fonts, icons and patterns',
      3: [
        'Choose from different themes',
        'Unsplash integration, custom icon for personal branding and more.',
      ],
    },
    supportsPlatforms: 'Supports platforms like Hashnode, Dev.to and Hugo FixIt',
    shareOnTwitter: 'Share on Twitter',
    summary: {
      title: 'Simple, quick, and easy to use',
      content: 'So you can focus on writing your blog and never worry about those cover images.',
    },
    tryItNow: 'It\'s Free! Try now',
    footer: {
      copyright: 'Made with 💛 by <a href="https://github.com/Lruihao" class="font-semibold" target="_blank" rel="noreferrer">Lruihao</a>',
      authorBlog: 'Checkout <a href="https://lruihao.cn/projects/" target="_blank" rel="noreferrer" class="text-purple-400 hover:text-pink-400">more cool stuff</a> I built',
      thanks: 'Thanks the original author',
    },
  },
  faq: {
    title: 'Frequently Asked Questions',
    questions: [
      {
        q: 'What is CoverView?',
        a: 'CoverView is a tool to create cover images for your blogs quickly and easily.',
      },
      {
        q: 'Is CoverView free?',
        a: 'Yes! CoverView is absolutely free to use.',
      },
      {
        q: 'Can I upload my custom brand logo?',
        a: 'Yes. Just search and select <span class="font-semibold">custom</span> in icon section and you can upload your own logo to personalize your cover images.<br /><span class="italic mt-2">See <a class="text-blue-500" href="https://twitter.com/WankhadeRutik/status/1518270774335111168?s=20&t=XMjbJpGAC7anadJ690_DUg" rel="noreferrer" target="_blank">example</a></span>.',
      },
      {
        q: 'Can I use CoverView for non-technical/personal blogs?',
        a: 'Yes! Why not? Even though CoverView was built with technical blogs in mind, you can still use it for your personal blogs. Check out the stylish theme for more.',
      },
      {
        q: 'Why use CoverView?',
        a: 'Because it\'s simple, quick and easy to use. Why spend hours designing when you can create cover images in seconds?',
      },
      {
        q: 'Want to support/sponsor the project?',
        a: 'If CoverView adds value in your life and you wish to support this project, you can sponsor me with <a class="font-semibold text-green-400 hover:underline" href="https://lruihao.cn/images/wechatpay.jpg" rel="noreferrer" target="_blank">WeChat</a>/<a class="font-semibold text-blue-400 hover:underline" href="https://lruihao.cn/images/alipay.jpg" rel="noreferrer" target="_blank">Alipay</a> or <a class="font-semibold text-pink-400 hover:underline" href="https://www.buymeacoffee.com/rutikwankhade" rel="noreferrer" target="_blank">buy Rutik Wankhade a coffee</a>.',
      },
    ],
    tips: {
      title: 'Want to know a secret? Click me',
      content: 'Blog titles with a minimum of 8 words have 21% better click-through',
    },
  },
  editor: {
    title: 'Blog title',
    author: 'Author',
    icon: 'Icon',
    custom: 'Custom',
    font: 'Font',
    color: 'Color',
    pattern: 'Pattern',
    platform: 'Platform/Size',
    resetBtn: 'Reset All',
    selectImgTips: 'Click on any image to select',
    downloadBtn: 'Download',
    copyBtn: 'Copy',
    themes: 'Themes',
    imageListEmpty: 'No images found',
    uploadScreenshot: 'Click to upload a screenshot or Ctrl+V to paste',
    searchPlaceholder: 'Search for images',
    fonts: {
      'font-Virgil': 'Virgil',
      'font-MMT': 'MMT',
      'font-Anek': 'Anek Latin',
      'font-Inter': 'Inter',
      'font-Poppins': 'Poppins',
      'font-mono': 'Monospace',
      'font-sans': 'Sans serif',
      'font-serif': 'Serif',
    },
  },
  pagination: {
    summary: 'Showing <span class="font-medium">{{page}}</span> to <span class="font-medium">{{totalPages}}</span> of <span class="font-medium">{{total}}</span> results',
    prev: 'Previous Page',
    next: 'Next Page',
  },
  orientation: {
    all: 'All',
    landscape: 'Landscape',
    portrait: 'Portrait',
    squarish: 'Squarish',
  },
  resultColors: {
    all: 'All',
    black_and_white: 'Black and White',
    black: 'Black',
    white: 'White',
    yellow: 'Yellow',
    orange: 'Orange',
    red: 'Red',
    purple: 'Purple',
    magenta: 'Magenta',
    green: 'Green',
    teal: 'Teal',
    blue: 'Blue',
  },
}
